import React from "react"
import LayoutCategory from "../../components/shared/layout/layout-category"
import {useIntl} from "gatsby-plugin-intl";

const id = "chalk-it";
const ChalkProduct = () => {
    const intl = useIntl()
    const data = {
        key: id,
        title: intl.formatMessage({id: `seo.chalk-it.title`}),
        description: intl.formatMessage({id: `seo.chalk-it.desc`}),
    }
    return (
        <LayoutCategory data={data}></LayoutCategory>
    )
}

export default ChalkProduct
